import { useState, useEffect, useContext } from "react";

export function Help() {
  const [projectId, setProjectId] = useState("");
  const bookingal_domain = "bookingal.de";
  useEffect(() => {
    (async () => {
      const response = await fetch("/api/admin/project_id", {
        credentials: "same-origin",
      });
      if (response.ok) {
        setProjectId(await response.json());
      }
    })();
  }, []);
  return (
    <>
      <p>
        Um das Buchungsformular zu benutzen, müssen folgende Zeilen in den
        HTML-Code der Seite eingefügt werden:
      </p>
      <ol role="list" className="mt-8 space-y-8 text-gray-600">
        <li className="gap-x-3">
          In den "head"-Abschnitt:
          <div className="flex items-center justify-between text-gray-800 border border-gray-800 bg-white font-mono text-sm py-3 px-4 w-full rounded-md">
            <div className="flex gap-1">
              <code>
                &lt;link href="https://{bookingal_domain}/css/style.css"
                rel="stylesheet" /&gt;
                <br />
                &lt;script src="https://{bookingal_domain}/js/alpine.min.js"
                defer&gt;&lt;/script&gt;
                <br />
                &lt;script src="https://{bookingal_domain}
                /js/bookingal.js"&gt;&lt;/script&gt;
              </code>
            </div>
            <span className="flex text-gray-800 cursor-pointer w-5 h-5 hover:text-gray-400 duration-200"></span>
          </div>
        </li>
        <li className="gap-x-3">
          An der Stelle, wo das Formular eingefügt werden soll:
          <div className="flex items-center justify-between text-gray-800 border border-gray-800 bg-white font-mono text-sm py-3 px-4 w-full rounded-md">
            <div className="flex gap-1">
              <code>&lt;div id="bookingal"&gt;&lt;/div&gt;</code>
            </div>
            <span className="flex text-gray-800 cursor-pointer w-5 h-5 hover:text-gray-400 duration-200"></span>
          </div>
        </li>
        <li className="gap-x-3">
          In den "script"-Abschnitt am Ende der Seite:
          <div className="flex items-center justify-between text-gray-800 border border-gray-800 bg-white font-mono text-sm py-3 px-4 w-full rounded-md">
            <div className="flex gap-1">
              <code>
                const project_id = '{projectId}'
                <br />
                loadbookingform(project_id)
              </code>
            </div>
            <span className="flex text-gray-800 cursor-pointer w-5 h-5 hover:text-gray-400 duration-200"></span>
          </div>
        </li>
      </ol>
    </>
  );
}
