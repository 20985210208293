export function VariantsEditor({ variant, handleSubmit, handleCancelButton }) {
  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6"
              id="adduse-modal-panel"
            >
              <div>
                <form
                  className="mt-8 space-y-6"
                  action="/api/admin/variants"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="col-span-6">
                        <label htmlFor="name">Beteichnung*</label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="email">Beschreibung</label>
                        <input
                          id="short_description"
                          name="short_description"
                          type="text"
                          className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        />
                        <p className="mt-3 text-sm leading-6 text-gray-600">
                          Diese Beschreibung wird unter der Bezeichung in der
                          Variantenauswahl angezeigt.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-1">
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      Speichern
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelButton}
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      Beenden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
